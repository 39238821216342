import restaurant from "./images/babillon.jpeg";
import img_card from "./images/5e.png";
import imgheaderrestaurant from "./images/b1.png";
import imgheaderrestaurantmb from "./images/b5.jpg";

import imgheader from "./images/header.webp";

import imgheaderm from "./images/headermb.webp";
import img2 from "./images/img2.jpg";
import imgheadermiini from "./images/ring.webp";
import imgheadermiinimb from "./images/header.jpg";

{/*valeriacosarca@gmail.com*/}

const data = {
    introData: [{
        title: "Invitatie Online",
        mire: "Vlad",
        mireasa: "Cristina",
        data: "4 Octombrie 2024",
        data_confirmare: "10 septembrie 2024",
        savedata: "~ Salvează Data ~",
        imgdesktop: imgheader,
        imgmobil: imgheaderm,
        email: "stratulatcristina660@gmail.com", 
       tel: "+37369429264",
       phone: "tel:+37369429264",
       viber: "viber://chat?number=%2B37369429264",
       whatsapp: "https://wa.me/+37369429264",
       messenger: "https://www.messenger.com/t/stratulat.cristina.370",
       tel1: "+373xxx",
       phone1: "tel:+373xxx",
       viber1: "viber://chat?number=%2B373xxx",
       whatsapp1: "https://wa.me/+373xxx",
       messenger1: "https://www.messenger.com/t/xx",
    }],
    cardData: [
        {
            id: 1,
            img: restaurant,
            title: "Locația Evenimentului",
            name: "Billion Nights",
            data: "4 octombrie 2024, vineri, ora 17:00",
            adress: "com. Coșnița, r-l. Dubăsari",
            harta: "https://maps.app.goo.gl/cKGhyivMs9zWdEBp8",
            iframe: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10853.753752365876!2d29.1379121!3d47.1492669!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c9676532947ec7%3A0x759c64d90c4efe6e!2sBilion%20Nights!5e0!3m2!1sro!2s!4v1711880001707!5m2!1sro!2s"  
        }

    ],
    blogDataNoi: [
        {
            id: 1,
            img: img_card,
            title1: "Ne căsătorim!",
            title2: "VĂ INVITĂM LA NUNTA NOASTRĂ!",
            message: "În ziua în care ne vom uni destinele, vrem să fim inconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a vă invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
            ps: "",
        }

    ],
    blogDataNasii: [
        {
            id: 2,
            img: img2,
            title1: "Invitație",
            title2: "Nunta noastră!",
            message: "In ziua in care ne vom uni destinele, vrem să fim înconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a va invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
             
        }
    ],
    blogDataOmSuflet: [
        {

            img: imgheaderrestaurant,
            img2: imgheaderrestaurantmb,
            title: "Nu uitați să luați cu voi un car de voie bună și opinci de dansat!!!",
            message: "P.S: Vom fi recunoscători pentru confirmarea prezenței DVS!",
            nasii: "",
            nasii_nume: "",
        }
    ],
    blogDataDrum: [
        {
           
            img: imgheadermiini,
          
            title: "Oameni de suflet!",
            message: "Alături de care vom scrie povestea noastră.",
            message1: "",
            parintii_nume1: "",
            parintii_nume2: "",
            parintii_nume3: "",
            parintii_nume4: "",
            nasii: "NAȘII",
            nasii_nume: "Mihai și Irina",
        }
    ],

}

export default data;